import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// // @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Api from "../Api/ApiUtils";
import routes from "routes.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/Frame.png";
import ProtectedRoute from "base/ProtectedRoute";
import { isModuleAccesible } from "../generalUtils";
import LocalStorage from "../Api/LocalStorage.js";
import Snackbar from "components/Snackbar/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";

let ps;
function getRoutes(routes) {
  return routes.flatMap((prop, key) => {
    if (prop.layout === "/superadmin") {
      if (prop.type === "menu") {
        return getRoutes(prop.items);
      } else return getRoute(prop, key);
    }
    return null;
  });
}
const getRoute = (item, key) => {
  console.log(item.path, "->", isModuleAccesible(item.access_criteria, "view"));
  if (item.access_criteria) {
    return (
      <ProtectedRoute
        path={item.layout + item.path}
        component={item.component}
        key={key}
        exact={item.exact ?? false}
        allowCondition={
          isModuleAccesible(item.access_criteria, "view")
            ? true
            : item.openlyAccessible
            ? true
            : false
        }
        redirectionPath="/superadmin/dashboard"
      />
    );
  } else
    return (
      <Route
        path={item.layout + item.path}
        component={item.component}
        key={key}
        exact={item.exact ?? false}
      />
    );
};

const switchRoutes = () => {
  return (
    <Switch>
      {getRoutes(routes)}
      <Redirect from="/superadmin" to="/superadmin/dashboard" />
    </Switch>
  );
};

const useStyles = makeStyles(styles);

const user = JSON.parse(localStorage.getItem("HamroSuperAdminInfo"));

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("white");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [br, setBR] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [notification, setNotification] = React.useState({
    type: "",
    message: "",
  });
  const handleCloseMsg = () => {
    setBR(false);
  };

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/superadmin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (!mainPanel.current) return;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Creative Tim"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes()}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes()}</div>
        )}
      </div>

      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </div>
  );
}
