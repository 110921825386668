import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import TableContainer from "@material-ui/core/TableContainer";
import "assets/css/material-dashboard-react.css";
// core components
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@material-ui/core/Collapse";
import { useState, useEffect } from "react";

// core components

import Api from "Api/ApiUtils";

import { IconButton } from "@material-ui/core";
import Snackbar from "../Snackbar/Snackbar.js";

import moment from "moment";

import Box from "@mui/material/Box";

import { useLocation, useParams } from "react-router-dom";

import { isModuleAccesible } from "generalUtils.js";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import CustomInput from "../../components/CustomInput/CustomInput.js";

const styles2 = {
  settingimage: {
    top: "300",
  },
  actionsCell: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  slotTableRowRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapsedRow: {
    "& > td": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "centre",
  },
  chip: {
    backgroundColor: "#282654",
    color: "white",
    marginRight: "0.25rem",
    marginBottom: "0.5rem",
  },
};

const useStyles = makeStyles(styles2);
const access_criteria = "premium_service_activities_management";

export default function CustomTable(props) {
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  const listing = {
    search: "",
    page: 0,
    limits: 10,
    sortValue: "desc",
    sortField: "createdAt",
  };

  const isEditSlotAccessible = useMemo(
    () => isModuleAccesible(access_criteria, "update"),
    []
  );
  const isViewSlotAccessible = useMemo(
    () => isModuleAccesible(access_criteria, "view"),
    []
  );
  const isDeleteSlotAccessible = useMemo(
    () => isModuleAccesible(access_criteria, "delete"),
    []
  );

  const user = JSON.parse(localStorage.getItem("HamroSuperAdminInfo"));
  const endTimeOnChange = useRef();
  const [loader, setLoader] = React.useState(true);

  const [tableParams, settableParams] = useState(listing);
  const [slotList, setSlotList] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [br, setBR] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    message: "",
  });
  const [slotToEdit, setSlotToEdit] = useState(null);
  const [startTimeInpVal, setStartTimeInpValue] = useState("");
  const [endTimeInpVal, setEndTimeInpValue] = useState("");
  const [slotId, setSlotId] = useState(null);

  const handleCloseMsg = () => {
    setBR(false);
  };

  const hasMultipleSlots = (slot) =>
    slot.multipleSlots && slot.multipleSlots.length > 0;

  const toggleSlotOpen = (slotId) =>
    setSlotId((prev) => (prev === slotId ? null : slotId));

  const toggleSlotForEdit = (slot) =>
    setSlotToEdit((prev) => (prev?.id === slot.id ? null : slot));

  const getHotelSlotList = () => {
    let slot_list = [];
    //console.log("inside func get hotel category");
    const payload = {
      hotel_id: user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id,
      packagesId: props.package_id,
    };
    Api.slotListing(tableParams, payload)

      .then((res) => {
        setLoader(false);
        //console.log(res);
        if (res && res.data && res.data.data) {
          res.data.data.rows &&
            res.data.data.rows.map((slots) => {
              slot_list.push({
                start_time: slots.start_time.split(":").slice(0, -1).join(":"),
                end_time: slots.end_time.split(":").slice(0, -1).join(":"),
                days: slots.days,
                id: slots.id,
                createdAt: moment(slots.createdAt).format("LL"),
                multipleSlots:
                  slots.multipleSlots?.map((ms) => ({
                    ...ms,
                    start_time: ms.start_time.split(":").slice(0, -1).join(":"),
                    end_time: ms.end_time.split(":").slice(0, -1).join(":"),
                  })) ?? [],
              });
            });
          setSlotList(slot_list);

          settotalRecords(res.data.data.count);

          props.setGetSlotDataCall(false);
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        setLoader(false);

        if (err) {
          setBR(true);
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  };

  const saveSlotData = async () => {
    const newData = {
      days: slotToEdit.days,
      id: slotToEdit.id,
      duration: location.state.duration,
      start_time: startTimeInpVal,
      end_time: endTimeInpVal,
    };

    props.editHotelSlot(newData);
    setSlotToEdit(null);
    setStartTimeInpValue("");
    setEndTimeInpValue("");
  };

  useEffect(() => {
    console.log("state-->", location.state);
    getHotelSlotList();
  }, [props.getSlotDataCall]);

  return (
    <div className={classes.tableResponsive}>
      {slotList && slotList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="slot-table">
            <TableHead>
              <TableCell style={{ width: "10%" }}></TableCell>
              <TableCell>Day</TableCell>
              <TableCell style={{ width: "25%" }}>Start Time</TableCell>
              <TableCell style={{ width: "25%" }}>End Time</TableCell>
              {isEditSlotAccessible ? (
                <TableCell style={{ width: "15%" }}>Actions</TableCell>
              ) : null}
            </TableHead>
            <TableBody>
              {slotList.map((sl, sidx) => (
                <>
                  <TableRow
                    key={`slot-${sidx}`}
                    className={
                      hasMultipleSlots(sl) ? classes.slotTableRowRoot : ""
                    }
                  >
                    <TableCell>
                      {hasMultipleSlots(sl) ? (
                        <IconButton onClick={() => toggleSlotOpen(sl.id)}>
                          {slotId === sl.id ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      ) : null}
                    </TableCell>
                    <TableCell>{sl.days}</TableCell>
                    <TableCell>
                      {slotToEdit?.id === sl.id ? (
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            style: { margin: 0 },
                          }}
                          inputProps={{
                            step: "3600000",
                            name: `start_time_${sl.id}`,
                            type: "time",
                            value: startTimeInpVal,
                            onChange: (e) =>
                              setStartTimeInpValue(e.target.value),
                            style: {
                              margin: 0,
                            },
                          }}
                        />
                      ) : (
                        sl.start_time
                      )}
                    </TableCell>
                    <TableCell>
                      {slotToEdit?.id === sl.id ? (
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            style: { margin: 0 },
                          }}
                          inputProps={{
                            step: "3600000",
                            name: `end_time_${sl.id}`,
                            type: "time",
                            value: endTimeInpVal,
                            onChange: (e) => setEndTimeInpValue(e.target.value),
                            style: {
                              margin: 0,
                            },
                          }}
                        />
                      ) : (
                        sl.end_time
                      )}
                    </TableCell>
                    {isEditSlotAccessible ? (
                      <TableCell>
                        {sl.id === slotToEdit?.id ? (
                          <div className={classes.actionsCell}>
                            <IconButton
                              aria-label="save-slot"
                              onClick={saveSlotData}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="close-slot"
                              onClick={() => {
                                toggleSlotForEdit(sl);
                                setStartTimeInpValue("");
                                setEndTimeInpValue("");
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <IconButton
                            aria-label="edit-slot"
                            onClick={() => {
                              toggleSlotForEdit(sl);
                              setStartTimeInpValue(sl.start_time);
                              setEndTimeInpValue(sl.end_time);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    ) : null}
                  </TableRow>
                  {hasMultipleSlots(sl) ? (
                    <TableRow className={classes.collapsedRow}>
                      <TableCell colSpan={1}></TableCell>
                      <TableCell colSpan={1}>
                        {slotId === sl.id ? "Slots Available" : ""}
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Collapse
                          in={slotId === sl.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1} className={classes.chipContainer}>
                            {sl.multipleSlots.map((slms, msidx) => (
                              <Chip
                                className={classes.chip}
                                label={`${slms.start_time} - ${slms.end_time}`}
                              />
                            ))}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
